export const uniqueObjects = <T>(array: T[], propertyName: keyof T) =>
  array.filter(
    (e, i) => array.findIndex((a) => a[propertyName] === e[propertyName]) === i
  );

export const uuid = (): string =>
  new Date().getTime().toString(36) + Math.random().toString(36).slice(2);

export const getFieldNameWithScope = (scope: string, field: string): string =>
  `${scope}.${field}`;

export function deepClone(obj: unknown) {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => deepClone(item));
  }

  const copy = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      copy[key] = deepClone(obj[key]);
    }
  }

  return copy;
}


export const getFormattedNumber = (number = 0): string => number.toLocaleString().replace(/,/g, ' ')
