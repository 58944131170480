import { EdgeTypes } from '@xyflow/react';

import FloatingEdge from './FloatingEdge';

const edgeTypes: EdgeTypes = {
  // @ts-ignore
  floating: FloatingEdge,
};

export default edgeTypes;
