import {
  createLicenseAction,
  deleteLicenseAction,
  getAllLicenses,
  updateLicenseAction,
} from 'store/licenses/licenses.actions'
import LicensesService from 'store/licenses/licenses.service'
import { ILicenseCreate } from 'types/license'
import { useAppDispatch } from './redux'

export const useLicenses = () => {
  const dispatch = useAppDispatch()

  const getLicenses = () => dispatch(getAllLicenses())

  const createLicense = async (data: ILicenseCreate) => {
    return await dispatch(createLicenseAction(data)).unwrap()
  }
  const updateLicense = async (data: ILicenseCreate, licenseId: number) => {
    return await dispatch(updateLicenseAction({ license: data, licenseId })).unwrap()
  }

  const createOrUpdateLicense = async (data: ILicenseCreate, licenseId?: number) => {
    if (licenseId) {
      return await updateLicense(data, licenseId)
    }
    return await createLicense(data)
  }

  const deleteLicense = async (licenseId: number) => {
    return await dispatch(deleteLicenseAction(licenseId)).unwrap()
  }

  const downloadFile = async (fileUrl: string) => {
    const response = await LicensesService.downloadFile(fileUrl)
    const file = response.data

    const url = URL.createObjectURL(new File([file], fileUrl))

    const a = document.createElement('a')
    a.href = url
    a.download = fileUrl
    a.click()
  }

  return { getLicenses, downloadFile, createOrUpdateLicense, deleteLicense }
}
