import './FormFieldCodeEditor.scss';

import { useField } from 'formik';
import { FC } from 'react';
import AceEditor from 'react-ace';

import { PythonCodeEditorProps } from './types';

export const FormFieldCodeEditor: FC<PythonCodeEditorProps> = ({
  name,
  defCode,
}) => {
  const [field, , helpers] = useField(name);
  return (
    <div className="form-field-code-editor">
      <div className="form-field-code-editor editor">
        <AceEditor
          height="100%"
          width="100%"
          placeholder="Код пишется на языке javaScript"
          mode="javascript"
          theme="monokai"
          name="blah2"
          value={field.value || defCode}
          onChange={helpers.setValue}
          fontSize={14}
          showPrintMargin={false}
          showGutter={true}
          highlightActiveLine={true}
          setOptions={{
            enableBasicAutocompletion: false,
            enableLiveAutocompletion: false,
            enableSnippets: false,
            showLineNumbers: true,
            tabSize: 2,
          }}
        />
      </div>
    </div>
  );
};
