import { AxiosResponse } from 'axios'
import { ENDPOINTS } from 'constants/api_endpoints'
import { ILicense, ILicenseCreate } from 'types/license'
import axiosApi from 'utils/api_helper'

export default class LicensesService {
  static async getLicenses() {
    const response: AxiosResponse<ILicense[]> = await axiosApi.get(`${ENDPOINTS.LICENSES}`)
    return response
  }

  static async getOneLicense(licenseId: number) {
    const response: AxiosResponse<ILicense> = await axiosApi.get(
      `${ENDPOINTS.LICENSES}/${licenseId}`
    )
    return response
  }

  static async createLicense(license: ILicenseCreate) {
    const formData = new FormData()
    Object.entries(license).forEach(([key, value]) => {
      formData.append(key, value)
    })
    const response: AxiosResponse<ILicense> = await axiosApi.post(ENDPOINTS.LICENSES, formData)
    return response
  }

  static async updateLicense(license: ILicenseCreate, licenseId: number) {
    const formData = new FormData()
    Object.entries(license).forEach(([key, value]) => {
      if (key === 'user') return
      if (key === 'validityEnd') return
      formData.append(key, value)
    })
    const response: AxiosResponse<ILicense> = await axiosApi.put(
      `${ENDPOINTS.LICENSES}/${licenseId}`,
      formData
    )
    return response
  }

  static async deleteLicense(licenseId: number) {
    const response = await axiosApi.delete(`${ENDPOINTS.LICENSES}/${licenseId}`)
    return response
  }

  static async downloadFile(fileUrl: string) {
    return axiosApi.get(`/${ENDPOINTS.LICENSES}/file/${fileUrl}`, {
      responseType: 'blob',
    })
  }
}
