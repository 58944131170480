import axios from 'axios'
import { SERVER_URL, ENDPOINTS } from 'constants/api_endpoints'
import { nameApp, nameToken } from 'constants/app_constants'
import { PUBLIC_ROUTES } from 'navigation'

export const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const axiosApi = axios.create({
  withCredentials: true,
  baseURL: SERVER_URL,
})

axiosApi.interceptors.request.use((config) => {
  config.headers!.Authorization = `Bearer ${localStorage.getItem(nameToken)}`
  config.headers!['Content-Type'] = 'application/json'
  return config
})

axiosApi.interceptors.response.use(
  (config) => {
    return config
  },

  async (error) => {
    const originalRequest = error.config

    if (error.response.status === 401 && error.config && !error.config._isRetry) {
      originalRequest._isRetry = true
      try {
        const response = await axios.post(`${SERVER_URL}/${ENDPOINTS.AUTH}/refresh`, undefined, {
          withCredentials: true,
        })
        localStorage.setItem(nameToken, response.data.accessToken)
        return axiosApi.request(originalRequest)
      } catch (e) {
        localStorage.removeItem(nameApp)
        localStorage.removeItem(nameToken)

        window.location.href = PUBLIC_ROUTES.LOGIN.path
      }
    }
    throw error
  }
)

export default axiosApi
