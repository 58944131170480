import Footer from 'components/Footer/Footer'
import Header from 'components/Header/Header'
import React, { FC, ReactNode } from 'react'
import { Container } from 'reactstrap'
import './Layout.scss'

interface Props {
  children: ReactNode
}

const Layout: FC<Props> = ({ children }) => {
  return (
    <div className="layout__wrapper">
      <Header />
      <Container className="page__wrapper" fluid>
        {children}
      </Container>
      <Footer />
    </div>
  )
}

export default Layout
