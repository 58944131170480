import './RuleItem.scss';

import { FC } from 'react';

import { ComparisonCondition } from '../../../ComparisonCondition';
import { LeftComparisonParameter } from '../../../LeftComparisonParameter';
import { RightComparisonParameter } from '../../../RightComparisonParameter';
import { RuleItemProps } from './types';
import { newRuleData } from 'modules/Rules/constants';


export const RuleItem: FC<RuleItemProps> = ({ arrayHelpers, scope, index }) => {
  return (
    <div className="rule-item">
      <LeftComparisonParameter name={`${scope}.leftOperand`} />
      <ComparisonCondition name={`${scope}.condition`} />
      <RightComparisonParameter name={`${scope}.rightOperand`} />

      <div className="rule-item__controls">
        <span
          className="btn-icon button__icon button__icon--trash bx bx-trash"
          onClick={() => arrayHelpers.remove(index)}
        />
        <span
          className="btn-icon button__icon button__icon--plus bx bx-plus"
          onClick={() => arrayHelpers.insert(index, newRuleData)}
        />
      </div>
    </div>
  );
};
