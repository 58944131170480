import './RightComparisonParameter.scss';

import { FieldNumberInput } from 'components/FormFieldsBuilder/fields/FieldNumberInput';
import { FC } from 'react';

import { RightComparisonParameterProps } from './types';

export const RightComparisonParameter: FC<RightComparisonParameterProps> = ({
  name,
  label,
}) => {
  return (
    <div className="right-comparison-parameter">
      <FieldNumberInput
        type="numberInput"
        label={label || 'Значение'}
        name={name}
        isShowError
      />
    </div>
  );
};
