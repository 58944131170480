import './BranchMenu.scss';

import { getOutgoers, useReactFlow } from '@xyflow/react';
import { FieldBase } from 'components/FormFieldsBuilder/fields/FieldBase';
import { FieldSelect } from 'components/FormFieldsBuilder/fields/FieldSelect';
import { useAppSelector } from 'hooks/redux';
import { Rules } from 'modules';
import { FC } from 'react';
import { getPowerCalculatorSchemesSelectedNode } from 'store/powerCalculator/powerCalculator.selectors';
import { Option } from 'types/common';
import { BranchData } from 'types/powerCalculator';

import { BranchMenuProps } from './types';

export const BranchMenu: FC<BranchMenuProps> = () => {
  const selectedNode = useAppSelector(getPowerCalculatorSchemesSelectedNode);

  const { getEdges, getNodes } = useReactFlow();

  if (!selectedNode) {
    return null;
  }

  const targetNodes: Option[] = getOutgoers(
    selectedNode,
    getNodes(),
    getEdges()
  ).map((node) => ({ label: `${node.data.name}`, value: node.id }));


  return (
    <div className="branch-menu">
      <div className="base-params__item">
        <FieldBase<BranchData>
          type="text"
          label="Название узла"
          name="name"
        />
      </div>
      <div className="base-params__item">
        <FieldSelect<BranchData>
          type="select"
          name="defNode"
          options={targetNodes}
          label="Путь по умолчанию"
          isCreatable={false}
          placeholder="Выбрать путь"
        />
      </div>

      <Rules name="conditions" />
    </div>
  );
};
