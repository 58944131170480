import React, { FC } from 'react'
import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import './BreadCrumbs.scss'

interface Props {
  title: string
  firstItem?: string
  secondItem: string
}

const BreadCrumbs: FC<Props> = ({ secondItem, title, firstItem = title }) => {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className='breadcrumbs__title'>{title}</div>
      <Breadcrumb>
        <BreadcrumbItem className='breadcrumbs__first_item'>{firstItem}</BreadcrumbItem>
        <BreadcrumbItem className='breadcrumbs__second_item'>{secondItem}</BreadcrumbItem>
      </Breadcrumb>
    </div>
  )
}

export default BreadCrumbs
