import {
  createCertificateAction,
  deleteCertificateAction,
  expandCertificateAction,
  getAllCertificates,
  updateCertificateAction,
} from 'store/certificates/certficates.actions'
import CertificatesService from 'store/certificates/certificates.service'
import { ICertificateCreate, ICertificateExpand } from 'types/certificate'
import { useAppDispatch } from './redux'

export const useCertificates = () => {
  const dispatch = useAppDispatch()

  const getCertificates = () => dispatch(getAllCertificates())

  const createCertificate = async (data: ICertificateCreate) => {
    return await dispatch(createCertificateAction(data)).unwrap()
  }

  const updateCertificate = async (data: ICertificateCreate, certificateId: number) => {
    return await dispatch(updateCertificateAction({ certificate: data, certificateId })).unwrap()
  }

  const createOrUpdateCertificate = async (data: ICertificateCreate, certificateId?: number) => {
    if (certificateId) {
      return await updateCertificate(data, certificateId)
    }
    return await createCertificate(data)
  }

  const deleteCertificate = async (certificateId: number) => {
    return await dispatch(deleteCertificateAction(certificateId)).unwrap()
  }

  const downloadFile = async (fileUrl: string) => {
    const response = await CertificatesService.downloadFile(fileUrl)
    const file = response.data

    const url = URL.createObjectURL(new File([file], fileUrl))

    const a = document.createElement('a')
    a.href = url
    a.download = fileUrl
    a.click()
  }

  const expandCertificate = async (data: ICertificateExpand, id: number) => {
    return await dispatch(expandCertificateAction({ data, id })).unwrap()
  }

  return {
    getCertificates,
    downloadFile,
    createOrUpdateCertificate,
    deleteCertificate,
    expandCertificate,
  }
}
