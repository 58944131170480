import './FieldNumberInput.scss';

import { NumericFormat } from 'react-number-format';
import { FormFieldLabel } from 'components/FormFieldsBuilder/components';
import { ErrorMessage, Field, FieldProps, useField } from 'formik';

import { FieldNumberInputProps } from './types';

export const FieldNumberInput = <T,>({isShowError,...field}: FieldNumberInputProps<T>) => {
  const [,{error},]= useField(field.name)
  
  const className = field.className ? `${field.className}` : `form-control`
  return (
    <>
      <FormFieldLabel htmlFor={field.name}>{field.label}</FormFieldLabel>
      <Field {...field} autoComplete="off" style={{}}>
        {({ field: formField }: FieldProps<T>) => (
          // @ts-expect-error
          <NumericFormat
      
            {...field}
            className={
              `${className} ${!!error && 'is-invalid'}`
            }
            value={formField.value as unknown as string}
            // eslint-disable-next-line
            onValueChange={(value) => {
              formField.onChange({
                target: {
                  name: field.name,
                  value:
                    `${value.value}` === '0' ? 0 : Number(value.value) || null,
                },
              });
            }}
            thousandSeparator=" "
          />
        )}
      </Field>
      {isShowError && <ErrorMessage
        name={field.name}
        component="span"
        className="form-error-message"
      />}
    </>
  );
};
