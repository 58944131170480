import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min'
import { ITableProps } from './TableTypes'
import { defaultSorted, getOptions } from './TableConstants'

const Table = (props: ITableProps) => {
  const { data, columns, keyField, tableProps, paginationProps } = props

  if (!data) return null

  return (
    <ToolkitProvider keyField={keyField} columns={columns} data={data} search>
      {(toolkitProps: any) => (
        <BootstrapTable
          keyField={keyField}
          responsive
          bordered={false}
          striped={false}
          defaultSorted={defaultSorted}
          rowClasses="courtcases-table-row"
          pagination={paginationFactory(getOptions(paginationProps))}
          classes={'table align-middle table-nowrap'}
          headerWrapperClasses={'thead-light'}
          {...toolkitProps.baseProps}
          {...tableProps}
        />
      )}
    </ToolkitProvider>
  )
}

export default Table
