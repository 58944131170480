import { IncomingLoadData } from 'types/powerCalculator';
import { numberField, stringField } from 'utils/validation';
import * as yup from 'yup';


const incomingLoadDataItemSchema = yup.object().shape({
  sourceName: stringField,
  additionalNumberRequests: numberField,
});

const restrictionsFieldValidation =
  yup.object().when([], ([], _schema, context) => {

    if (
      context?.value?.cpuUseRestrictions
    ) {

      _schema = _schema.concat(yup.object().shape({
        cpuMin: numberField,
        cpuMax: numberField,
      }))
    }

    if (
      context?.value?.ramUseRestrictions
    ) {

      _schema = _schema.concat(yup.object().shape({
        ramMax: numberField,
        ramMin: numberField,
      }))
    }

    if (
      context?.value?.hddUseRestrictions
    ) {
      _schema = _schema.concat(yup.object().shape({
        hddMax: numberField,
        hddMin: numberField,
      }))
    }

    if (
      context?.value?.gpuUseRestrictions
    ) {
      _schema = _schema.concat(yup.object().shape({
        gpuMax: numberField,
        gpuMin: numberField,
      }))
    }
    return _schema;
  })

const incomingLoadField =
  yup.object().when([], ([], _schema, context) => {
    const incomingLoad = context.value as IncomingLoadData;
    Object.keys(incomingLoad).forEach((key) => {
      _schema = _schema.concat(yup.object().shape({
        [key]: incomingLoadDataItemSchema
      }))
    }
    )
    return _schema
  })



export const baseParamsValidation = yup
  .object()
  .shape({
    name: stringField,

    cpu: numberField,
    ram: numberField,
    hdd: numberField,
    gpu: numberField,

    cpuCode: stringField,
    ramCode: stringField,
    hddCode: stringField,
    gpuCode: stringField,

    newReplicaCpu: numberField,
    newReplicaRam: numberField,
    newReplicaHdd: numberField,
    newReplicaGpu: numberField,
    parameterNameForCalculatePower: stringField,
    
    scalingConditions: yup.array().of(
      yup.object().shape(
        {
          leftOperand: stringField,
          rightOperand: numberField
        }
      )
    ),
    conditionsSeparateVm: yup.array().of(
      yup.object().shape(
        {
          leftOperand: stringField,
          condition: yup.string().required('Обязательное'),
          rightOperand: numberField
        }
      )
    ),
    incomingLoad: incomingLoadField
  }).concat(restrictionsFieldValidation)

