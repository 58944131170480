import './PowerCalculatorForm.scss';

import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { Alert, Button, Card, CardBody, Form, FormGroup, Input } from 'reactstrap';

import { getAllSchemes } from 'store/powerCalculator/powerCalculator.actions';
import { useAppDispatch, useAppSelector } from 'hooks/redux';


interface IError {
  type: string;
  message: string;
}

interface Props {
  handelCalculate: (data: FormData) => void
}

type FormData = { schemeId: string, inputParams: Record<string, number> }

const PowerCalculatorForm: FC<Props> = ({ handelCalculate }) => {

  const [error] = useState<null | IError>(null);
  const dispatch = useAppDispatch()
  const schemes = useAppSelector((state) => state.powerCalculator.schemes);
  const {
    setValues,
    handleChange,
    handleSubmit,
    values,
  } = useFormik<FormData | {}>({
    initialValues: {
    },

    async onSubmit(values: FormData | {}) {
      if ('schemeId' in values) {
        handelCalculate(values)
      }

    },
  });

  useEffect(() => {
    dispatch(getAllSchemes())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelectScheme: React.ChangeEventHandler<HTMLInputElement> = (event) => {

    const selectedSchemeId = event.target.value;
    const selectedScheme = schemes.find(scheme => `${scheme.id}` === selectedSchemeId);

    const inputParams = selectedScheme?.scheme.inputParams?.reduce((params, parameter) => ({ ...params, [parameter.value]: 0 }), {}) || {};
    if (selectedScheme) {
      setValues({ schemeId: selectedSchemeId, inputParams })
    } else {
      setValues({})
    }
  }

  const inputParams = 'schemeId' in values ? schemes.find(scheme => `${scheme.id}` === values?.schemeId)?.scheme?.inputParams || [] : [];
  const isSelectedScheme = 'schemeId' in values;
  return (
    <Card className="border-0 flex-grow-1 mt-3">
      <CardBody className="d-flex flex-column">
        <div className="power-calculator-form ">

          <FormGroup className="ms-2 me-2 mb-2">
            <div className="input__title">Выбор проекта:</div>
            {/* @ts-ignore */}
            <Input   name="role" type="select" onChange={handleSelectScheme} value={values?.schemeId}>
              <option value={undefined}>
                Выбрать схему
              </option>
              {schemes.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.description}
                </option>
              ))}
            </Input>
     
          </FormGroup>
         
          {isSelectedScheme && <>
          <Form id='power-calculator' onSubmit={handleSubmit} className='power-calculator-form__input-params'>
            <div>
              {inputParams.map((parameter) => (
                <FormGroup key={parameter.value}>
                  <div className="input__title">{parameter.label}</div>
                  <Input
                    required
                    name={`inputParams.${[parameter.value]}`}
                    type="number"
                    onChange={handleChange}
                    invalid={!values.inputParams[parameter.value]}
                    value={values.inputParams[parameter.value]}
                    step={0.0001}
                  />
                </FormGroup>
              ))}
            </div>

            {!!error && error.type === 'server' && (
              <Alert color="danger">{error.message}</Alert>
            )}
          </Form>
          </>
          }
          <FormGroup className="text-end mt-4">
            <Button
              form='power-calculator'
              color="primary"
              type="submit"
              disabled={!isSelectedScheme}
            >
              Рассчитать
            </Button>
          </FormGroup>
        </div>
      </CardBody>
    </Card>
  );
};

export default PowerCalculatorForm;

