import ConfirmModal from 'components/ConfirmModal/ConfirmModal'
import React, { FC } from 'react'
import { IUserData } from 'types/user'
import './UsersDeleteModal.scss'

interface Props {
  user: IUserData | null
  isOpen: boolean
  deleteHandler: (userId: number) => Promise<unknown>
  closeHandler: () => void
}

const UsersDeleteModal: FC<Props> = ({ user, isOpen, deleteHandler, closeHandler }) => {
  const clickHandler = async () => {
    if (!user) return
    await deleteHandler(user.id)
    return closeHandler()
  }

  return (
    <ConfirmModal closeHandler={closeHandler} isOpen={isOpen} submitHandler={clickHandler}>
      <div className="text-center">
        <div className="users__modal_delete_title">Удалить пользователя: </div>
        <div className="users__modal_delete_login">{user?.name ?? ''}?</div>
      </div>
    </ConfirmModal>
  )
}

export default UsersDeleteModal
