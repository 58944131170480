import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getAllSchemes } from './powerCalculator.actions'
import { InputParameterData, ISchemeData, NodeData } from 'types/powerCalculator'
import { NodeProps, Node } from '@xyflow/react';

interface IInitState {
  isSubmitting: boolean;
  schemes: ISchemeData[]
  selectedNode: NodeProps<Node<NodeData>> | null;
  inputParams: InputParameterData[];
  defInputParams: InputParameterData[];
}

const initialState: IInitState = {
  isSubmitting: false,
  schemes: [],
  selectedNode: null,
  defInputParams: [

  ],
  inputParams: [
   
  ],
}

export const powerCalculatorSlice = createSlice({
  initialState,
  name: 'powerCalculator',
  reducers: {
    setSelectedNode: (
      state,
      { payload }: PayloadAction<IInitState['selectedNode']>
    ) => {
      state.selectedNode = payload;
    },
    setInputParams: (
      state,
      { payload }: PayloadAction<IInitState['inputParams']>
    ) => {
      state.inputParams = payload;
    },
    setDefInputParams: (
      state,
      { payload }: PayloadAction<IInitState['defInputParams']>
    ) => {
      state.defInputParams = payload;
    },
    setStatusSubmitting: (
      state,
      { payload }: PayloadAction<IInitState['isSubmitting']>
    ) => {
      state.isSubmitting = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getAllSchemes.fulfilled, (state, action) => {
      state.schemes = action.payload
    })
  },
})

export const powerCalculatorReducer = powerCalculatorSlice.reducer
