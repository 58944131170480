import * as yup from 'yup';
import { baseParamsValidation } from 'modules/BaseParams/validation';
import { stringField } from 'utils/validation';

export const microserviceMenuValidation =  yup
.object()
.shape({
    parameterWithDataDaysStorage: stringField,
    parameterWithDataNumberGeneratedPerDay: stringField
}).concat(baseParamsValidation)

