import { AxiosResponse } from 'axios'
import { ENDPOINTS } from 'constants/api_endpoints'
import { ICertificate, ICertificateCreate, ICertificateExpand } from 'types/certificate'
import axiosApi from 'utils/api_helper'

export default class CertificatesService {
  static async getCertificates() {
    const response: AxiosResponse<ICertificate[]> = await axiosApi.get(`${ENDPOINTS.CERTIFICATES}`)
    return response
  }

  static async getOneCertificate(certificateId: number) {
    const response: AxiosResponse<ICertificate> = await axiosApi.get(
      `${ENDPOINTS.CERTIFICATES}/${certificateId}`
    )
    return response
  }

  static async createCertificate(certificate: ICertificateCreate) {
    const formData = new FormData()
    Object.entries(certificate).forEach(([key, value]) => {
      formData.append(key, value)
    })
    const response: AxiosResponse<ICertificate> = await axiosApi.post(
      ENDPOINTS.CERTIFICATES,
      formData
    )
    return response
  }

  static async updateCertificate(certificate: ICertificateCreate, certificateId: number) {
    const formData = new FormData()
    Object.entries(certificate).forEach(([key, value]) => {
      if (key === 'user') return
      if (key === 'validityEnd') return
      formData.append(key, value)
    })
    const response: AxiosResponse<ICertificate> = await axiosApi.put(
      `${ENDPOINTS.CERTIFICATES}/${certificateId}`,
      formData
    )
    return response
  }

  static async expandCertificate(data: ICertificateExpand, certificateId: number) {
    const formData = new FormData()

    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value)
    })

    const response: AxiosResponse<ICertificate> = await axiosApi.put(
      `${ENDPOINTS.CERTIFICATES}/expand/${certificateId}`,
      formData
    )
    return response
  }

  static async deleteCertificate(certificateId: number) {
    const response = await axiosApi.delete(`${ENDPOINTS.CERTIFICATES}/${certificateId}`)
    return response
  }

  static async downloadFile(fileUrl: string) {
    return axiosApi.get(`/${ENDPOINTS.CERTIFICATES}/file/${fileUrl}`, {
      responseType: 'blob',
    })
  }
}
