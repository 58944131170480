import PowerCalculatorsTable from './components/PowerCalculatorsTable/PowerCalculatorsTable';
import { useEffect } from 'react';
import { useAppDispatch } from 'hooks/redux';
import { getAllSchemes } from 'store/powerCalculator/powerCalculator.actions';
import PowerCalculatorCreateForm from './components/PowerCalculatorCreateForm/PowerCalculatorCreateForm';
import { useModal } from 'hooks/useModal';
import PowerCalculatorDeleteModal from './components/PowerCalculatorDeleteModal/PowerCalculatorDeleteModal';
import { ISchemeData, PowerCalculatorModalType } from 'types/powerCalculator';
import PowerCalculatorService from 'store/powerCalculator/powerCalculator.service';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';


const PowerCalculators = () => {
  const { modal, closeModal, openModal } = useModal<
  PowerCalculatorModalType,
    ISchemeData
  >();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllSchemes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteHandler = async () => {
    await PowerCalculatorService.deleteScheme(modal.item?.id || '');
    dispatch(getAllSchemes());

    closeModal();
  };

  return (
    <>
      <BreadCrumbs secondItem="Список" title="Конфигуратор мощности" />
      <PowerCalculatorCreateForm
        isOpen={modal.isOpen === 'create'}
        close={closeModal}
        create={closeModal}
      />
      <PowerCalculatorsTable
        openModalHandler={openModal}
        openModal={openModal}
      />
      <PowerCalculatorDeleteModal
        isOpen={modal.isOpen === 'delete'}
        closeHandler={closeModal}
        deleteHandler={deleteHandler}
        schemeDescription={modal.item?.description || ''}
      />

    </>
  );
};

export default PowerCalculators;
