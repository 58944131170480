import { stringField } from 'utils/validation';
import * as yup from 'yup';

export const branchMenuValidation = yup
  .object()
  .shape({
    name: stringField,
    defNode: stringField,
    conditions: yup.array().of(
      yup.object().shape(
        {
          leftOperand: stringField,
          condition: yup.string().required('Обязательное'),
          rightOperand: stringField
        }
      )
    )
  })

