export const defaultSorted = [
  {
    dataField: 'id',
    order: 'asc',
  },
]

const customTotal = (from: number, to: number, size: number) => (
  <span className="react-bootstrap-table-pagination-total">
    Показано {from} с {to} по {size} записей
  </span>
)

export const getOptions = (props: any) => {
  return {
    sizePerPage: 5,
    paginationSize: 5,
    pageStartIndex: 1,
    nextPageText: 'Далее',
    prePageText: 'Назад',
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    hideSizePerPage: true,
    ...props,
  }
}
