 const mapTabWithError = {
    base: ['name', 'cpu', 'ram', 'hdd', 'gpu', 'cpuMin', 'cpuMax', 'ramMax', 'ramMin', 'hddMax', 'hddMin', 'gpuMax', 'gpuMin', 'parameterNameForCalculatePower'],
    vm: ['conditionsSeparateVm'],
    scalingConditions: ['newReplicaCpu', 'newReplicaRam', 'newReplicaHdd', 'newReplicaGpu', 'scalingConditions'],
    incomingLoad: ['incomingLoad'],
    data_storage: ['parameterWithDataDaysStorage', 'parameterWithDataNumberGeneratedPerDay']
  }
  
  export const isExistErrorInTab = (tabKey: string, errors: string[]) => {
    const listErrorsFromTab = mapTabWithError[tabKey]
    return !!listErrorsFromTab?.find((errorFromTab: string) => errors.includes(errorFromTab))
  }
  