import './MicroserviceMenu.scss';

import { Tabs } from 'components/Tabs';
import {
  BaseParams,
  IncomingLoad,
  RecordStorage,
  Rules,
  ScalingConditions,
} from 'modules';
import { FC } from 'react';
import { Tab } from 'react-bootstrap';

import { MicroserviceMenuProps } from './types';
import { TabTitle } from 'components/TabTitle';
import { isExistErrorInTab } from '../utils';
import { useFormikContext } from 'formik';

export const MicroserviceMenu: FC<MicroserviceMenuProps> = () => {
  const { errors } = useFormikContext();
  const errorsAsList: string[] = Object.keys(errors)

  return (
    <Tabs defaultActiveKey="base">
      <Tab id="base" eventKey="base" title={
        <TabTitle label='Основное' hasError={isExistErrorInTab('base', errorsAsList)} />
      }>
        <BaseParams />
      </Tab>
      <Tab
        id="conditionsSeparateVm"
        eventKey="conditionsSeparateVm"

        title={
          <TabTitle label='VM' hasError={isExistErrorInTab('vm', errorsAsList)} />
        }
      >
        <Rules name="conditionsSeparateVm" />
      </Tab>
      <Tab
        id="scalingConditions"
        eventKey="scalingConditions"

        title={
          <TabTitle label='Репликация' hasError={isExistErrorInTab('scalingConditions', errorsAsList)} />
        }
      >
        <ScalingConditions name="scalingConditions" />
      </Tab>
      <Tab
        id="incomingLoad"
        eventKey="incomingLoad"

        title={
          <TabTitle label='Входящая нагрузка' hasError={isExistErrorInTab('incomingLoad', errorsAsList)} />
        }
      >
        <IncomingLoad name="incomingLoad" />
      </Tab>
      <Tab id="data_storage" eventKey="data_storage"
        title={
          <TabTitle label='Хранение данных' hasError={isExistErrorInTab('data_storage', errorsAsList)} />
        }>
        <RecordStorage />
      </Tab>
    </Tabs>
  );
};
