import Table from 'components/Table/Table'
import { useAppSelector } from 'hooks/redux'
import React, { FC, lazy, Suspense, useState } from 'react'
import CustomDropdown from 'components/CustomDropdown/CustomDropdown'
import { ModalStateOpenType } from 'pages/Licenses/Licenses'
import { useSort } from 'hooks/useSort'
import { ICertificate } from 'types/certificate'
import LicensesTableSort from 'pages/Licenses/components/LicensesTable/components/LicensesTableSort'
import { getCertificatesTableSelector } from 'store/certificates/certificates.selectors'
import { certificatesColumns } from './columns'

const CertificatesPreview = lazy(
  () => import('../CertificatesPreviewDocument/CertificatesPreviewDocument')
)

interface Props {
  downloadFile: (fileUrl: string) => void
  openModal: (condition: ModalStateOpenType, item?: ICertificate) => void
}

interface IPreviewState {
  isOpen: boolean
  item: ICertificate | null
}

const CertificatesTable: FC<Props> = ({ openModal, downloadFile }) => {
  const [previewState, setPreviewState] = useState<IPreviewState>({
    isOpen: false,
    item: null,
  })
  const { authors, companies, items } = useAppSelector(getCertificatesTableSelector)
  const { changeSortState, filteredItems, sort } = useSort(items, {
    author: 'all',
    company: 'all',
  })

  const previewCloseHandler = () => {
    setPreviewState((prevState) => ({ ...prevState, isOpen: false }))
  }

  const previewOpenHandler = (item: ICertificate) => {
    setPreviewState({ item, isOpen: true })
  }

  const extendedColumns = [
    ...certificatesColumns,
    {
      dataField: 'menu',
      formatter(_: never, row: ICertificate) {
        return (
          <CustomDropdown
            previewOpenHandler={previewOpenHandler}
            downloadTitle="Скачать сертификат"
            item={row}
            downloadFile={downloadFile}
            openModalHandler={openModal}
            expand
          />
        )
      },
      text: '',
    },
  ]

  return (
    <>
      <LicensesTableSort
        btnTitle="Создать сертификат"
        firstSortTitle="Партнёр:"
        secondSortTitle="Ответственный:"
        openModal={openModal}
        changeSortState={changeSortState}
        sort={sort}
        authors={authors}
        companies={companies}
      />
      <div className="d-flex flex-column justify-content-between mt-3 flex-grow-1 licenses__table_wrapper">
        <Table
          columns={extendedColumns}
          data={filteredItems}
          paginationProps={{
            sizePerPage: 6,
          }}
          keyField="id"
          tableProps={{
            rowClasses: 'licenses__table_row',
          }}
        />
      </div>
      <Suspense>
        <CertificatesPreview
          close={previewCloseHandler}
          isOpen={previewState.isOpen}
          item={previewState.item}
        />
      </Suspense>
    </>
  )
}

export default CertificatesTable
