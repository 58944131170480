import './NodeTypes.scss';

import { NodeTypes } from '@xyflow/react';

import BranchNode from './BranchNode';
import BrokerNode from './BrokerNode';
import DataBaseNode from './DataBaseNode';
import MicroserviceNode from './MicroserviceNode';

const nodeTypes: NodeTypes = {
  branch: BranchNode,
  microservice: MicroserviceNode,
  dataBase: DataBaseNode,
  broker: BrokerNode,
};

export default nodeTypes;
