import { useEffect } from 'react';
import { getIncomers,  useReactFlow } from '@xyflow/react';
import { useField } from 'formik';
import { useAppSelector } from 'hooks/redux';
import { getPowerCalculatorSchemesSelectedNode } from 'store/powerCalculator/powerCalculator.selectors';
import { IncomingLoadData } from 'types/powerCalculator';

export const useIncomingLoadItems = (name: string) => {
    const [{ value: incomingLoadItems }, , { setValue: setIncomingLoadItems }] =
    useField<IncomingLoadData>(name);

  const selectedNode = useAppSelector(getPowerCalculatorSchemesSelectedNode);
  const { getEdges, getNodes } = useReactFlow();
  const edges = getEdges();
  const nodes = getNodes();

  // @ts-ignore
  const connectedNodes = getIncomers(selectedNode, nodes, edges);

  const getNode = (id: string) => {
    return nodes.find((node) => node.id === id);
  };

  const getIncomingEdges = (id: string) => {
    return edges.filter((edge) => edge.target === id);
  };

  useEffect(() => {
    const getSourceItem = (node, sourceName) => ({
        ...incomingLoadItems[node.id],
        sourceName,
        additionalNumberRequests: incomingLoadItems[node.id]?.additionalNumberRequests ?? 0,
      });
    
      const processBranchNode = (node, items) => {
        const incomingEdges = getIncomingEdges(node.id);
        incomingEdges.forEach((edge) => {
          const sourceNode = getNode(edge.source);
          if (sourceNode) {
            items[sourceNode.id] = getSourceItem(sourceNode, sourceNode.data.name);
          }
        });
      };
    
      const newItems = connectedNodes.reduce((acc, node) => {
        if (node.type === 'branch') {
          processBranchNode(node, acc);
        } else {
          acc[node.id] = getSourceItem(node, node.data.name);
        }
        return acc;
      }, {});
    
      setIncomingLoadItems(newItems);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return incomingLoadItems
};
