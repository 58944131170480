import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { nameToken } from 'constants/app_constants'
import { ILoginData } from 'types/user'
import AuthService from './auth.service'

export const fetchUserData = createAsyncThunk('users/me', async () => {
  const response = await AuthService.fetchUserData()
  return response.data
})

export const login = createAsyncThunk(
  'auth/login',
  async (data: ILoginData, { rejectWithValue }) => {
    try {
      const response = await AuthService.login(data)
      localStorage.setItem(nameToken, response.data.accessToken)
      return response.data
    } catch (error) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response!.data!.error as string)
      }
    }
  }
)

export const logout = createAsyncThunk('auth/logout', async () => {
  return localStorage.removeItem(nameToken)
})
