import { AxiosResponse } from 'axios'
import { ENDPOINTS } from 'constants/api_endpoints'
import { IRegisterData, IUserData } from 'types/user'
import axiosApi from 'utils/api_helper'

export default class UserService {
  static async getAllUsers() {
    const response: AxiosResponse<IUserData[]> = await axiosApi.get(`${ENDPOINTS.USER}/all`)

    return response
  }

  static async register(data: IRegisterData) {
    const response: AxiosResponse<IUserData> = await axiosApi.post(
      `${ENDPOINTS.AUTH}/register`,
      data
    )

    return response
  }

  static async editUser(data: IRegisterData, userId: number) {
    const response: AxiosResponse<IUserData> = await axiosApi.put(
      `${ENDPOINTS.USER}/${userId}`,
      data
    )

    return response
  }

  static async deleteUser(userId: number) {
    const response = await axiosApi.delete(`${ENDPOINTS.USER}/${userId}`)

    return response
  }
}
