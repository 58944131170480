import './ScalingConditions.scss';

import { Button } from 'reactstrap';
import { FieldNumberInput } from 'components/FormFieldsBuilder/fields/FieldNumberInput';
import { FieldArray, FieldArrayRenderProps, useField } from 'formik';
import { FC } from 'react';
import { BaseParamsData } from 'types/powerCalculator';

import { ScalingCondition } from './components';
import { ScalingConditionsProps } from './types';
import { newScalingConditionData } from './constants';


export const ScalingConditions: FC<ScalingConditionsProps> = ({ name }) => {
  const [{ value = [] }] = useField(name);

  const renderScalingConditions = (arrayHelpers: FieldArrayRenderProps) => {
    if (!value.length) {
      return (
        <Button
          onClick={() => arrayHelpers.unshift(newScalingConditionData)}
        >
          Добавить условие
        </Button>
      );
    }

    return value.map((_, index: number) => (
      <ScalingCondition
        key={index}
        scope={`${name}.[${index}]`}
        index={index}
        arrayHelpers={arrayHelpers}
      />
    ));
  };

  const renderResourceInputs = () => (
    <>
      {[
        { label: 'CPU (%)', name: 'newReplicaCpu' },
        { label: 'RAM (Мб)', name: 'newReplicaRam' },
        { label: 'HDD (Мб)', name: 'newReplicaHdd' },
        { label: 'GPU (кол-во)', name: 'newReplicaGpu' },
      ].map((input) => (
        <div className="base-params__item" key={input.name}>
          <FieldNumberInput<BaseParamsData>
            type="numberInput"
            label={input.label}
            name={input.name}
            isShowError
          />
        </div>
      ))}
    </>
  );

  return (
    <div className="scaling-conditions">
      <span>Накладные расходы на реплику</span>
      {renderResourceInputs()}
      <hr />
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <>
            {renderScalingConditions(arrayHelpers)}
            <span className="form-error-message">{}</span>
          </>
        )}
      />
    </div>
  );
};
