import { useReactFlow } from "@xyflow/react";
import { useAppDispatch, useAppSelector } from "./redux";
import { getPowerCalculatorSchemesDefInputParams, getPowerCalculatorSchemesInputParams } from "store/powerCalculator/powerCalculator.selectors";
import PowerCalculatorService from "store/powerCalculator/powerCalculator.service";
import { useParams } from "react-router-dom";
import { ISchemeData } from "types/powerCalculator";
import { setStatusSubmitting } from "store/powerCalculator/powerCalculator.actions";

export const useSaveScheme = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch()
    
    const constructorInputParams = useAppSelector(getPowerCalculatorSchemesInputParams);
    const constructorDefInputParams = useAppSelector(
        getPowerCalculatorSchemesDefInputParams
    );
    const { getEdges, getNodes } = useReactFlow<ISchemeData['scheme']['nodes'][0], ISchemeData['scheme']['edges'][0]>();
    return () => {
        if(!id) {
            return;
        }
        dispatch(setStatusSubmitting(true))
        const data: ISchemeData['scheme'] = {
            nodes: getNodes(),
            edges: getEdges(),
            inputParams: constructorInputParams,
            defInputParams: constructorDefInputParams
        }
        return PowerCalculatorService.updateScheme(id, data).then(()=>{
            dispatch(setStatusSubmitting(false))
        })
    }
}
