import { createContext, useContext, useState } from 'react';
import { TypeNode } from 'types/powerCalculator';

type Context = [TypeNode | null, (_: TypeNode | null) => void]
const DnDContext = createContext<
Context
>([null, (_: TypeNode | null) => {}]);

export const DnDProvider = ({ children }) => {
  const [type, setType] = useState<TypeNode | null>(null);

  return (
    <DnDContext.Provider value={[type, setType]}>
      {children}
    </DnDContext.Provider>
  );
};

export default DnDContext;

export const useDnD = () => {
  return useContext(DnDContext);
};
