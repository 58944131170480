import { FC } from 'react';
import { Button } from 'reactstrap';

interface Props {
  openModal: (condition: false | 'delete' |'create', item?: any) => void;
}

const PowerCalculatorsTableSort: FC<Props> = ({ openModal }) => {
  return (
    <div className='d-flex justify-content-between align-items-center'>
  
      <div className='d-flex align-items-center gap-3'></div>
      <Button
        className='page__btn'
        color='primary'
        onClick={() => openModal('create')}
      >
        Создать новый конструктор
      </Button>
    </div>
  );
};

export default PowerCalculatorsTableSort;
