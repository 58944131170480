import { createAsyncThunk } from '@reduxjs/toolkit'
import PowerCalculatorService from './powerCalculator.service'
import { powerCalculatorSlice } from './powerCalculator.reducer';


export const getAllSchemes = createAsyncThunk('schemes', async () => {
  const response = await PowerCalculatorService.getAllSchemes();

  return response.data
})

export const { setSelectedNode, setInputParams, setDefInputParams , setStatusSubmitting} = powerCalculatorSlice.actions;
