import './InputParamsView.scss';

import { FC } from 'react';

import { InputParamsViewProps } from './types';

export const InputParamsView: FC<InputParamsViewProps> = ({ params }) => {
  return (
    <div className="input-params-view">
      {params.map((parameter) => (
        <div key={parameter.value}>{parameter.label}</div>
      ))}
    </div>
  );
};
