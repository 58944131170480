import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { licensesReducer } from './licenses/licenses.reducer'
import { authReducer } from './auth/auth.reducer'
import { usersReducer } from './users/users.reducer'
import { nameApp } from 'constants/app_constants'
import { certificatesReducer } from './certificates/certificates.reducer'
import { powerCalculatorReducer } from './powerCalculator/powerCalculator.reducer'

export const rootReducer = combineReducers({
  auth: authReducer,
  licenses: licensesReducer,
  certificates: certificatesReducer,
  users: usersReducer,
  powerCalculator: powerCalculatorReducer,
})

const stateFromLocalStorage = localStorage.getItem(nameApp)
const serializedstateFromLocalStorage = stateFromLocalStorage
  ? JSON.parse(stateFromLocalStorage)
  : {}

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: serializedstateFromLocalStorage,
})

store.subscribe(() => {
  const state = store.getState()

  const serializedState = JSON.stringify({
    auth: state.auth,
  })
  localStorage.setItem(nameApp, serializedState)
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
