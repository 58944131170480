import React, { FC, PropsWithChildren } from 'react'
import { Button, Modal, ModalBody } from 'reactstrap'

interface Props extends PropsWithChildren {
  isOpen: boolean
  closeHandler: () => void
  submitHandler: () => void
  className?: string
  bodyClassName?: string
}

const ConfirmModal: FC<Props> = ({
  closeHandler,
  isOpen,
  submitHandler,
  children,
  className,
  bodyClassName,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={closeHandler} centered className={className}>
      <span className="modal__close_icon" onClick={closeHandler}>
        <i className="fas fa-times" />
      </span>

      <ModalBody className={`d-flex flex-column modal__paddings ${bodyClassName}`}>
        {children}
        <div className="modal__delete_btns_wrapper">
          <Button color="primary" outline onClick={closeHandler}>
            Отмена
          </Button>
          <Button color="primary" onClick={submitHandler}>
            Ок
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ConfirmModal
