import './LoadParameters.scss';

import { AddScope } from 'components/FormFieldsBuilder';
import { FieldNumberInput } from 'components/FormFieldsBuilder/fields/FieldNumberInput';
import { FC } from 'react';
import { IncomingLoadData } from 'types/powerCalculator';

import { LoadParametersProps } from './types';

export const LoadParameters: FC<LoadParametersProps> = ({ scope }) => {
  type LoadParametersDataWithScope = AddScope<IncomingLoadData[string], string>;

  return (
    <div className="load-parameters">
      <div>
        <FieldNumberInput<LoadParametersDataWithScope>
          type="numberInput"
          label="Количество дополнительных запросов от сервиса"
          name={`${scope}.additionalNumberRequests`}
          isShowError
        />
      </div>
      {/* <div>
        <FieldNumberInput<LoadParametersDataWithScope>
          type="numberInput"
          label="RAM (Мб)"
          name={`${scope}.ram`}
        />
      </div>
      <div>
        <FieldNumberInput<LoadParametersDataWithScope>
          type="numberInput"
          label="HDD (Мб)"
          name={`${scope}.hdd`}
        />
      </div>
      <div>
        <FieldNumberInput<LoadParametersDataWithScope>
          type="numberInput"
          label="GPU (кол-во)"
          name={`${scope}.gpu`}
        />
      </div> */}
    </div>
  );
};
