import './PowerCalculatorsTable.scss'

import Table from 'components/Table/Table'
import { useAppSelector } from 'hooks/redux'
import { FC } from 'react'
import { powerCalculatorsTableColumns } from './constants'
import PowerCalculatorsTableSort from './components/PowerCalculatorsTableSort'

import { getPowerCalculatorSchemesSelector } from 'store/powerCalculator/powerCalculator.selectors'
import { ISchemeData, PowerCalculatorModalType } from 'types/powerCalculator'
import PowerCalculatorsDropDown from '../PowerCalculatorsDropdown/PowerCalculatorsDropDown'
import { Card, CardBody } from 'reactstrap'


interface Props {
  openModalHandler: (condition: PowerCalculatorModalType, scheme: ISchemeData) => void
  openModal: (condition: PowerCalculatorModalType, item?: ISchemeData) => void
}

const PowerCalculatorsTable: FC<Props> = ({ openModal, openModalHandler }) => {
  const schemes = useAppSelector(getPowerCalculatorSchemesSelector)

  const extendedColumns = [
    ...powerCalculatorsTableColumns,
    {
      dataField: 'edit',
      text: '',
      formatter: (_row: number, cell: ISchemeData) => {
        return <PowerCalculatorsDropDown scheme={cell} openModalHandler={openModalHandler} />
      },
    },
  ]

  return (
    <Card className="border-0 flex-grow-1 mt-3">
      <CardBody className="d-flex flex-column">
        <PowerCalculatorsTableSort
          openModal={openModal}
        />
        <div className="d-flex flex-column justify-content-between mt-3 flex-grow-1 power-calculators-table">
          <Table
            columns={extendedColumns}
            data={schemes}
            paginationProps={{
              sizePerPage: 6,
            }}
            keyField="id"
            tableProps={{
              rowClasses: 'power-calculators-table__row',
            }}
          />
        </div>
      </CardBody>
    </Card>
  )
}

export default PowerCalculatorsTable
