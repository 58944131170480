import './RecordStorage.scss';

import { LeftComparisonParameter } from 'modules/LeftComparisonParameter';
import { FC } from 'react';

import { RecordStorageProps } from './types';

export const RecordStorage: FC<RecordStorageProps> = () => {
  return (
    <div className="record-storage">
      <div className="base-params__item">
        <LeftComparisonParameter
          label="Показатель количества дней хранения информации"
          name="parameterWithDataDaysStorage"
        />
      </div>
      <div className="base-params__item">
        <LeftComparisonParameter
          label="Показатель количества запросов в день"
          name="parameterWithDataNumberGeneratedPerDay"
        />
      </div>
    </div>
  );
};
