import React, { PropsWithChildren, useState } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import shapesImg from 'assets/images/shapes.svg'
import './CustomDropdown.scss'
import { ModalStateOpenType } from 'pages/Licenses/Licenses'
import { useAppSelector } from 'hooks/redux'
import { LicenseValidityType } from 'types/license'

interface Props<ItemType> {
  openModalHandler: (condition: ModalStateOpenType, item: ItemType) => void
  downloadFile: (fileUrl: string) => void
  item: ItemType
  downloadTitle: string
  expand?: boolean
  previewOpenHandler?: (item: ItemType) => void
}

const CustomDropdown = <ItemType extends { filePath: string; validity: LicenseValidityType }>({
  openModalHandler,
  item,
  downloadFile,
  downloadTitle,
  expand = false,
  previewOpenHandler,
}: PropsWithChildren<Props<ItemType>>) => {
  const isAdmin = useAppSelector((state) => state.auth.role === 'admin')
  const [isOpen, setisOpen] = useState(false)

  const toggleHandler = () => {
    setisOpen(!isOpen)
  }

  const downloadFileHandler = () => {
    downloadFile(item.filePath)
  }

  const previewClickHandler = previewOpenHandler
    ? () => {
        previewOpenHandler(item)
      }
    : false

  const openClickHandler = (condition: ModalStateOpenType) => {
    return openModalHandler(condition, item)
  }

  return (
    <div className="text-center">
      <Dropdown toggle={toggleHandler} isOpen={isOpen}>
        <DropdownToggle className="dropdown__btn">
          <img src={shapesImg} alt="" />
        </DropdownToggle>
        <DropdownMenu className="table__dropdown_list">
          {previewClickHandler && (
            <DropdownItem
              color="secondary"
              className="table__dropdown_item_edit"
              onClick={previewClickHandler}
            >
              Предпросмотр
            </DropdownItem>
          )}
          <DropdownItem
            color="secondary"
            className="table__dropdown_item_edit"
            onClick={downloadFileHandler}
          >
            {downloadTitle}
          </DropdownItem>
          {isAdmin && (
            <>
              {expand && item.validity === 'year' && (
                <DropdownItem
                  color="secondary"
                  className="table__dropdown_item_edit"
                  onClick={() => openClickHandler('expand')}
                >
                  Продлить сертификат
                </DropdownItem>
              )}
              <DropdownItem
                color="secondary"
                className="table__dropdown_item_edit"
                onClick={() => openClickHandler('edit')}
              >
                Редактировать
              </DropdownItem>
              <DropdownItem
                className="table__dropdown_item_delete"
                onClick={() => openClickHandler('delete')}
              >
                Удалить
              </DropdownItem>
            </>
          )}
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default CustomDropdown
