import { useMemo, useState } from 'react'
import { UserInLicenseType } from 'types/license'

export const useSort = <SortType, ItemType extends { user: UserInLicenseType }>(
  items: ItemType[],
  sortState: SortType
) => {
  const [sort, setSort] = useState(sortState)

  const changeSortState = (sortItem: { [key: string]: string }) => {
    setSort({ ...sort, ...sortItem })
  }

  const filteredItems = useMemo(() => {
    let filteredItems: ItemType[] = [...items]
    Object.entries(sort).forEach(([key, value]) => {
      if (value !== 'all') {
        filteredItems = filteredItems.reduce((values: ItemType[], item: ItemType) => {
          const itemValue = key !== 'author' ? item[key] : item.user?.name
          if (itemValue === value) values.push(item)
          return values
        }, [])
      }
    })
    return filteredItems
  }, [sort, items])

  return {
    filteredItems,
    sort,
    changeSortState,
  }
}
