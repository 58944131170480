import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/store'

const selectPowerCalculator = (state: RootState) => state.powerCalculator

export const getPowerCalculatorSchemesSelector = createSelector(selectPowerCalculator, (state) => state.schemes)

export const getPowerCalculatorSchemesInputParams = createSelector(
  selectPowerCalculator,
  (constructor) => constructor.inputParams
);

export const getPowerCalculatorSchemesAsOptions = createSelector(
  selectPowerCalculator,
  (constructor) => [...constructor.defInputParams, ...constructor.inputParams]
);

export const getPowerCalculatorSchemesDefInputParams = createSelector(
  selectPowerCalculator,
  (constructor) => constructor.defInputParams
);

export const getPowerCalculatorSchemesSelectedNode = createSelector(
  selectPowerCalculator,
  (constructor) => constructor.selectedNode
);

export const getPowerCalculatorStatusSubmitting = createSelector(
  selectPowerCalculator,
  (constructor) => constructor.isSubmitting
);
