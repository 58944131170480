import { useCallback, useState } from 'react'

export const useModal = <ConditionType, ItemType>(
  initState: {
    isOpen: ConditionType | false
    item: ItemType | null
  } = { isOpen: false, item: null }
) => {
  const [state, setState] = useState(initState)

  const openModal = (condition: ConditionType, item?: ItemType) => {
    setState({
      isOpen: condition,
      item: item ?? null,
    })
  }

  const closeModal = useCallback(() => {
    setState({
      item: state.item,
      isOpen: false,
    })
  }, [state.item])

  return { modal: state, openModal, closeModal }
}
