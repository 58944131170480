export interface ILoginData {
  login: string
  password: string
}

export enum UserRoleType {
  user = 'user',
  admin = 'admin',
}

export interface IRegisterData extends ILoginData {
  name: string
  password: string
  role: UserRoleType
}

export interface IUserData extends Omit<IRegisterData, 'password'> {
  id: number
  createdAt: Date
}

export interface ILoginResponse extends IUserData {
  accessToken: string
}
export const displayRoles = {
  admin: 'Администратор',
  user: 'Пользователь',
}
// export const userRoles = {
//   user: 'user',
//   admin: 'admin',
// }
