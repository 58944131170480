import './TabTitle.scss';

import { FC } from 'react';

import { TabTitleProps } from './types';

export const TabTitle: FC<TabTitleProps> = ({ label, hasError }) => {
  return (
    <span className="tab-title" data-test={`user-sidebar__tab-${label}`}>
      {label}
      {!!hasError && <span className="tab-title__error" />}
    </span>
  );
};
