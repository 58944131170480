import './ComparisonCondition.scss';

import { FieldSelect } from 'components/FormFieldsBuilder/fields/FieldSelect';
import { FC } from 'react';

import { ComparisonConditionProps } from './types';

const options = [
  {
    value: 'equal',
    label: '=',
  },
  {
    value: 'not_equal',
    label: '!=',
  },
  {
    value: 'bigger',
    label: '>',
  },
  {
    value: 'smaller',
    label: '<',
  },
  {
    value: 'bigger_or_equal',
    label: '>=',
  },
  {
    value: 'lower_or_equal',
    label: '<=',
  },
];

export const ComparisonCondition: FC<ComparisonConditionProps> = ({ name }) => {
  return (
    <div className="comparison-condition">
      <FieldSelect
        type="select"
        name={name}
        options={options}
        label="Условие"
        isCreatable={false}
        placeholder="Условие"
      />
    </div>
  );
};
