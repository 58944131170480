import './IncomingLoad.scss';

import { FC } from 'react';
import { Accordion } from 'react-bootstrap';

import { LoadParameters } from './components';
import { IncomingLoadProps } from './types';
import { useIncomingLoadItems } from 'hooks/useIncomingLoadItems';

export const IncomingLoad: FC<IncomingLoadProps> = ({ name }) => {
   const incomingLoadItems = useIncomingLoadItems(name);

  return (
    <div className="incoming-load">
      <Accordion defaultActiveKey="0">
        {Object.entries(incomingLoadItems).map(([key, value]) => {
          return (
            <Accordion.Item eventKey={key}>
              <Accordion.Header>
                {`Нагрузка от сервиса "${value.sourceName}"`}
              </Accordion.Header>
              <Accordion.Body>
                <LoadParameters scope={`${name}.${key}`} />
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </div>
  );
};
