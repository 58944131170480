export const powerCalculatorsTableColumns = [
  {
    dataField: 'createdAt',
    text: 'Дата создания',
    formatter: (createdAt: number) => new Date(createdAt).toLocaleDateString()
  },
  {
    dataField: 'description',
    text: 'Описание',
  },
]
